class PaymentData {
  payment_type = '';
  plan_id = '';
  payment_method = '';
  reference = '';
  document = '';
  file = '';
  amount = '';
  card_type = 'debit';
  card_number = '';
  account_type = 'CC';
  cvv = '';
  expiration_month = '';
  expiration_year = '';
  twofactor_auth = '';
  dni = '';

  // Bancamiga
  numero = '';
  mes = '';
  ano = '';
  cvc = '';
  nombre = '';
  tipo_persona = '';
  cedula = '';

  // Pago movil
  destination_id = 'V18367443';
  //Pago movil
  // comentado, hbailitar para producción
  //destination_id = 'J311624457';
  destination_mobile_number = '584241513063';
  //destination_mobile_number = '584142405474';
  origin_mobile_number = '';

  constructor(data) {
    if (data && Object.keys(data).length > 0) {
      this.payment_type = data.payment_type;
      this.plan_id = data.plan_id;
      this.payment_method = data.payment_method;
      this.reference = data.reference;
      this.document = data.document;
      this.file = data.file;
      this.amount = data.amount;
      this.card_type = data.card_type;
      this.card_number = data.card_number;
      this.account_type = data.account_type;
      this.cvv = data.cvv;
      this.expiration_month = data.expiration_month;
      this.expiration_year = data.expiration_year;
      this.twofactor_auth = data.twofactor_auth;
      this.dni = data.dni;

      // Bancamiga
      this.numero = data.numero;
      this.mes = data.mes;
      this.ano = data.ano;
      this.cvc = data.cvc;
      this.nombre = data.nombre;
      this.tipo_persona = data.tipo_persona;
      this.cedula = data.cedula;

      // Pago movil
      this.destination_id = data.destination_id;
      this.destination_mobile_number = data.destination_mobile_number;
      this.origin_mobile_number = data.origin_mobile_number;
    }
  }
}

export default PaymentData;
