import {
    createStore,
    combineReducers,
    compose,
    applyMiddleware,
} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ReduxThunk from 'redux-thunk';

import { appReducer } from './appReducer';
import { affiliationReducer } from './affiliationReducer';
import { authenticationReducer } from './authenticationReducer';

const middleware = [ReduxThunk];

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'],
};

// if you're also using redux-thunk, add it as a middleware
// const createStoreWithMiddleware = compose(applyMiddleware(ReduxThunk))(createStore);

const rootReducer = combineReducers({
    app: appReducer,
    affiliation: affiliationReducer,
    auth: authenticationReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composedEnhancers = compose(
  applyMiddleware(...middleware),
);

export default function configureStore(initialState = {}) {
    const store = createStore(persistedReducer, initialState, composedEnhancers);
    return { store, persistor: persistStore(store) };
}

// export default function configureStore(initialState = {}) {
//     return createStoreWithMiddleware(rootReducer, initialState);
// };
