import React, { Component } from 'react';

import { Card, CardContent, Divider } from '@material-ui/core/';

import './_widgets.scss';

 class CardInformation extends Component {
  constructor(props) {
    super();
    this.state = {
      cardInformation: props.cardInformation,
      top: false,
      left: false,
      bottom: false,
      right: false,
    };
  }

   render() {
    const cardInformation = this.state.cardInformation.map((information, i) => {
      return  <Card className="card-information flex jc-c" key={i}>
                <CardContent className="fw">
                  <h2 className="color-dark text-center">
                    { information.title }
                  </h2>
                  <Divider />
                  <p className="color-light flex flex-col text-center m-t20">
                    { information.text }
                  </p>
                </CardContent>
              </Card>
    });

     return(
      <div className="flex wrap m-t20">
        { cardInformation }
        <Card className="card-information flex jc-c">
          <CardContent className="fw">
            <h2 className="color-dark text-center">
              Valores
            </h2>
            <Divider />
            <div className="color-light text-center flex f-11em flex-col m-t20">
              {['Excelencia', 'Calidad', 'Innovación', 'Dinamismo', 
                'Personalización', 'Compromiso', 'Responsabilidad'].map((text, i) => {
                  return <span key={i}>{text}</span>
              })}
            </div>
          </CardContent>
        </Card>
      </div>
     );
   }
 }

export default CardInformation;