import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import configureStore from './store/configureStore';
import registerServiceWorker from './registerServiceWorker';

const {store, persistor} = configureStore( window.__REDUX_STATE__ || {} );

const AppBundle = (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
      </PersistGate>
    </ReduxProvider>
);

window.onload = () => {
    Loadable.preloadReady().then(() => {
        const renderMethod = !!module.hot ? ReactDOM.render : ReactDOM.hydrate;
        renderMethod(
            AppBundle,
            document.getElementById('root')
        )
    });
};

registerServiceWorker();
