import React from 'react';

function SplashScreen() {
  return(
    <div className="splash-screen">
      <div className="spinner"></div>
    </div>
  );
}

export default SplashScreen;