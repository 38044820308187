import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Route, Switch } from "react-router-dom";
import { LinearProgressBar, SplashScreen } from './components/widgets'

import logo from './assets/images/logo-v.svg';
import './theme/styles.scss';
import {clearAllData} from './store/affiliationReducer';
import {setToken} from './components/utils/Token';

const AsyncPageNavbar = Loadable({
  loader: () => import(/* webpackChunkName: "Navbar" */ './components/navigation/Navbar'),
  loading: () => <div></div>,
  modules: ['pageNavbar'],
});

//const AsyncPageFooter = Loadable({
//  loader: () => import(/* webpackChunkName: "Footer" */ './components/footer/Footer'),
//  loading: () => <div></div>,
//  modules: ['pageFooter'],
//});

const AsyncPageHome = Loadable({
  loader: () => import(/* webpackChunkName: "Home" */ './pages/Home'),
  loading: () => <LinearProgressBar />,
  modules: ['pageHome'],
});

const AsyncPageContact = Loadable({
  loader: () => import(/* webpackChunkName: "Contact" */ './pages/Contact'),
  loading: () => <LinearProgressBar />,
  modules: ['pageContact'],
});

const AsyncPageAboutUs = Loadable({
  loader: () => import(/* webpackChunkName: "AboutUs" */ './pages/AboutUs'),
  loading: () => <LinearProgressBar />,
  modules: ['pageAboutUs'],
});

const AsyncPageServices = Loadable({
  loader: () => import(/* webpackChunkName: "Services" */ './pages/Services'),
  loading: () => <LinearProgressBar />,
  modules: ['pageServices'],
});

const AsyncPageResponsabilitySocial = Loadable({
  loader: () => import(/* webpackChunkName: "ResponsabilitySocial" */ './pages/ResponsabilitySocial'),
  loading: () => <LinearProgressBar />,
  modules: ['pageResponsabilitySocial'],
});

const AsyncPageFaqs = Loadable({
  loader: () => import(/* webpackChunkName: "Faqs" */ './pages/Faqs'),
  loading: () => <LinearProgressBar />,
  modules: ['pageFaqs'],
});

const AsyncPageTestDigital = Loadable({
  loader: () => import(/* webpackChunkName: "TestDigital" */ './pages/testDigital/TestDigital'),
  loading: () => <LinearProgressBar />,
  modules: ['pageTest'],
});

const AsyncPageAffiliation = Loadable({
  loader: () => import(/* webpackChunkName: "Affiliation" */ './pages/affiliation/'),
  loading: () => <LinearProgressBar />,
  modules: ['pageAffiliation'],
});

const AsyncPageLogin = Loadable({
  loader: () => import(/* webpackChunkName: "Login" */ './pages/login/'),
  loading: () => <LinearProgressBar />,
  modules: ['pageLogin'],
});

const AsyncPageAdminAffiliations = Loadable({
  loader: () => import(/* webpackChunkName: "Affiliations" */ './pages/admin/affiliations'),
  loading: () => <LinearProgressBar />,
  modules: ['pageAdminAffiliations'],
});

const TermsConditions = Loadable({
  loader: () => import(/* webpackChunkName: "TermsConditions" */ './components/affiliation/TermsConditions'),
  loading: () => <LinearProgressBar />,
  modules: ['TermsConditions'],
});

const splash = () => <SplashScreen />

class App extends Component {

  state = {
    loading: true,
  }

  componentDidMount() {
    const {clearAllData, token} = this.props;
    clearAllData();
    setToken(token);
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 2000);
  }

  render() {
    const {authenticated} = this.props;

    const NotFound = () => (
      <div className="p-v48 container flex-full h-80vh not-found">
        <img src={logo} alt="logo venemergencia"/>
        <div className="flex jc-c flex-col m-l20">
          <h1>404</h1>
          <p className="nm strong color-light f-1em">Lo sentimos, no se encontró la página que estas buscando.</p>
        </div>
      </div>
    )

    if (this.state.loading === true) return splash();

    return (
      <div className="content">
        {/* { this.state.loading === true && splash()} */}
        <AsyncPageNavbar authenticated={authenticated} />
        <div className="content m-t80">
          <Switch>
            {/*<Route exact path="/" component={AsyncPageHome} />*/}
            <Route exact path="/" component={AsyncPageLogin}/>
            <Route exact path="/covid19testdigital" component={AsyncPageTestDigital} />
            <Route exact path="/covid19testdigital/resultado" component={AsyncPageTestDigital} />
            <Route exact path="/venemergencia" component={AsyncPageAboutUs}/>
            <Route exact path="/contactanos" component={AsyncPageContact}/>
            <Route exact path="/servicios" component={AsyncPageServices}/>
            <Route exact path="/responsabilidad-social" component={AsyncPageResponsabilitySocial}/>
            <Route exact path="/preguntas-frecuentes" component={AsyncPageFaqs}/>
            <Route exact path="/iniciar-sesion" component={AsyncPageLogin}/>
            <Route exact path="/afiliaciones" component={AsyncPageAdminAffiliations}/>
            <Route exact path="/afiliacion" component={AsyncPageAffiliation}/>
            <Route exact path="/TermsConditions" component={TermsConditions}/>
            <Route component={NotFound} />
          </Switch>
        </div>
        {/*<AsyncPageFooter /> */} {/*FOOTER COMENTED */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  token: state.auth.token,
  user: state.auth.user,
});

export default withRouter(
    connect(mapStateToProps, { clearAllData })(App)
);
