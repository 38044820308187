class BasicData {
  id = '';
  token = '';
  first_name = '';
  last_name = '';
  email = '';
  phone_main = '';
  password1 = '';
  password2 = '';
  affiliated = false;

  constructor(data) {
    if (data && Object.keys(data).length > 0) {
      this.id = data.id;
      this.token = data.token;
      this.first_name = data.first_name;
      this.last_name = data.last_name;
      this.email = data.email;
      this.phone_main = data.phone_main;
      this.password1 = data.password1;
      this.password2 = data.password2;
      this.affiliated = data.affiliated;
    }
  }
}

export default BasicData;
