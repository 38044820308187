import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import variables from '../../theme/_variables.scss';

const styles = {
  root: {
    flexGrow: 1,
  },
  linearColorPrimary: {
    backgroundColor: `${variables.primaryRedColor}`,
  },
  linearBarColorPrimary: {
    backgroundColor: `${variables.backRedColor}`,
  },
};

class LinearProgressBar extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <LinearProgress 
          classes={{
            colorPrimary: classes.linearColorPrimary,
            barColorPrimary: classes.linearBarColorPrimary,
          }}
        />
      </div>
    );
  }
}

LinearProgressBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LinearProgressBar);