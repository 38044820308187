class PayerData {
  id = '';
  first_name = '';
  last_name = '';
  dni_type = '';
  dni = '';
  birthdate = null;
  email_main = '';
  email_second = '';
  phone_main = '';
  phone_second = '';
  country = '';
  state = '';
  city = '';
  municipality = '';
  location = '';
  type= 'natural'

  constructor(data) {
    if (data && Object.keys(data).length > 0) {
      this.id = data.id;
      this.first_name = data.first_name;
      this.last_name = data.last_name;
      this.dni_type = data.dni_type;
      this.dni = data.dni;
      this.birthdate = data.birthdate || null;
      this.email_main = data.email_main || data.email;
      this.email_second = data.email_second;
      this.phone_main = data.phone_main;
      this.phone_second = data.phone_second;
      this.country = data.country;
      this.state = data.state;
      this.city = data.city;
      this.type = data.type;
      this.municipality = data.municipality;
      this.location = data.location;
    }
  }
}

export default PayerData;
