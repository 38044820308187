import React from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 2,
    color: '#fff',
  },
});

function LoaddingFullScreen(props) {
    const { classes } = props;
    return(
      <div className="loadding-component flex-col">
        <CircularProgress className={`progress ${classes.progress}`} size={50} thickness={3} />
        <span className="color-white container text-center">{ props.message }</span>
      </div>
    );
}

LoaddingFullScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoaddingFullScreen);