import BasicData from '../models/BasicData';
import PayerData from '../models/PayerData';
import PlanData from '../models/PlanData';
import PaymentData from '../models/PaymentData';

const initialState = {
  beneficiaries: [],
  basicData: new BasicData({}),
  planData: new PlanData({}),
  paymentData: new PaymentData({}),
  payerData: new PayerData({}),
  beneficiariesData: [new BasicData({})],
  autoCompleteState: [],
  dashboard: {},
};

export const affiliationReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'SET_BASIC_DATA':
      // localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        basicData: new BasicData(action.payload),
      };
    case 'SET_BENEFICIARIES_DATA':
      return {
        ...state,
        beneficiariesData: action.payload,
      };
    case 'CLEAR_ALL_DATA':
      localStorage.removeItem('token');
      return {
        ...state,
        basicData: new BasicData({}),
        planData: new PlanData({}),
        paymentData: new PaymentData({}),
        payerData: new PayerData({}),
        beneficiaries: [],
        beneficiariesData: [new BasicData({})],
      };
    case 'SET_PLAN_DATA':
      return {
        ...state,
        planData: new PlanData(action.payload),
      };
    case 'SET_PAYER_DATA':
      return {
        ...state,
        paymentData: new PayerData(action.payload),
      };
    case 'SET_PAYMENT_DATA':
      return {
        ...state,
        paymentData: new PaymentData(action.payload),
      };
    case 'SET_BENEFICIARIES':
      return {
        ...state,
        beneficiaries: action.payload,
      };
    case 'SET_DASHBOARD':
      return {
        ...state,
        dashboard: action.payload,
      };
    default:
      return state;
  }
};

export const setBasicData = payload => ({ type: 'SET_BASIC_DATA', payload });

export const setPlanData = payload => ({ type: 'SET_PLAN_DATA', payload });

export const setPayerData = payload => ({ type: 'SET_PAYER_DATA', payload });

export const setPaymentData = payload => ({ type: 'SET_PAYMENT_DATA', payload });

export const setBeneficiariesData = payload => ({ type: 'SET_BENEFICIARIES_DATA', payload });

export const setBeneficiaries = payload => ({ type: 'SET_BENEFICIARIES', payload });

export const clearAllData = () => ({ type: 'CLEAR_ALL_DATA' });

export const setDashboard = payload => ({ type: 'SET_DASHBOARD', payload });