class PlanData {
  id = '';
  us_amount = '';

  constructor(data) {
    if (data && Object.keys(data).length > 0) {
      this.id = data.id;
      this.us_amount = data.us_amount;
    }
  }
}

export default PlanData;
