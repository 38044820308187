import User from '../models/User';
import {setToken} from '../components/utils/Token';

const initialState = {
  authenticated: false,
  token: null,
  user: new User({}),
};

export const authenticationReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'SET_USER':
      setToken(action.payload.token);
      return {
        ...state,
        token: action.payload.token,
        user: new User(action.payload.user),
        authenticated: !!action.payload.token,
      };
      break;
    case 'LOGOUT':
      setToken(null);
      return {
        ...state,
        token: null,
        user: new User({}),
        authenticated: false,
      };
      break;
    default:
      return state;
  }
}

export const setUser = payload => ({ type: 'SET_USER', payload });
export const logout = payload => ({ type: 'LOGOUT', payload });
