class User {
  id = -1;
  affiliated = false;
  birthdate = null;
  dni = '';
  dni_type = '';
  email = '';
  first_name = '';
  last_name = '';
  phone_main = '';
  role = -1;
  gender = '';

  constructor(data) {
    this.id = data.id;
    this.affiliated = data.affiliated;
    this.birthdate = data.birthdate;
    this.dni = data.dni;
    this.dni_type = data.dni_type;
    this.email = data.email;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.phone_main = data.phone_main;
    this.role = data.role;
    this.gender = data.gender;
  }
}

export default User;
